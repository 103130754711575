body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* This centers the items */
  align-items: center;
  height: 100%;
}

.card {
  display: inline-block;
  width: 30vw;
  height: 50vw;
  max-width: 300px;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  /* border: 0.5px solid gray; */
  margin-top: 2vw;
  margin-bottom: 2vw;
  transition: filter 0.3s ease;
  position: relative
}

.card:hover {
  filter: brightness(1.5); 
}

.card p {
  text-align: center;
  background-color: #555555;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 1.5vw;
}